import React from 'react'

import * as S from './styles'

import LogoIconWithText from '@components/core/icons/logo-with-text'
import Button from '../button'

export default function Header() {
  // const [isPhoneLinkActive, setPhoneLinkActive] = useState(false)
  // const isExtraSmallMobile = useMedia(`(max-width: ${theme.breakpoints.xxs})`)
  // const isMobile = useMedia(`(max-width: ${theme.breakpoints.sm})`)

  // const currentPhoneIconColor = isPhoneLinkActive ? theme.colors.primary : theme.colors.secondary

  return (
    <S.Header>
      <S.Link to="/">
        <LogoIconWithText />
      </S.Link>
      {/* <S.Link to="/">{isExtraSmallMobile ? <LogoIcon /> : <LogoIconWithText />}</S.Link> */}
      <S.CenteredContainer>
        {/* <S.ActionItemWrapper>
          <PhoneLink
            phoneNumber={contacts.phoneNumber}
            onFocus={() => void setPhoneLinkActive(true)}
            onBlur={() => void setPhoneLinkActive(false)}
            onMouseEnter={() => void setPhoneLinkActive(true)}
            onMouseLeave={() => void setPhoneLinkActive(false)}
          >
            {isMobile ? (
              <PhoneIcon color={currentPhoneIconColor} />
            ) : (
              <S.CenteredFlex>
                <PhoneIcon color={currentPhoneIconColor} />
                {contacts.phoneNumber}
              </S.CenteredFlex>
            )}
          </PhoneLink>
        </S.ActionItemWrapper> */}
        <S.ActionItemWrapper>
          <Button
            // as="a"
            // href={contacts.onlineBookingUrl}
            // target="_blank"
            // rel="noopener noreferrer"
            disabled
          >
            Objednat se
          </Button>
        </S.ActionItemWrapper>
      </S.CenteredContainer>
    </S.Header>
  )
}
