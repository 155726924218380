import React from 'react'
import type { ReactNode } from 'react'

import Header from '../header'
import Footer from '../footer'
import ToastConfiguration from '../toast-notification/config'

import * as S from './styled'

import '@theme/reset.css'
import Announcement from '@components/core/announcement'
import useToastAnnouncement from '@hooks/use-toast-announcement'
import { GLOBAL_DATA } from '@resources/data/global'

interface ILayoutProps {
  children: ReactNode
}

export default function Layout({ children }: ILayoutProps) {
  useToastAnnouncement(GLOBAL_DATA.announcement)

  return (
    <>
      <Header />
      <S.Container>
        <Announcement />

        <main>{children}</main>
      </S.Container>
      <Footer />
      <ToastConfiguration />
    </>
  )
}
