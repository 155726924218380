import styled from 'styled-components'
import GatsbyImage from 'gatsby-image'

import { toRem } from '@theme/utils'

const CardImage = styled(GatsbyImage)`
  border-top-left-radius: ${toRem(4)};
  border-top-right-radius: ${toRem(4)};
`

export default CardImage
