import React from 'react'
import * as S from './styles'

import Anchor from '@components/core/anchor'
import PhoneLink from '@components/core/phone-link'
import EmailLink from '@components/core/email-link'
import PhoneIcon from '@components/core/icons/phone'
import EmailIcon from '@components/core/icons/email'
import LocationIcon from '@components/core/icons/location'
import InfoIcon from '@components/core/icons/info'
import AccessTimeIcon from '@components/core/icons/access-time'

import { contacts } from '@resources/data/contact'

import theme from '@theme'

export default function ContactList() {
  return (
    <ul>
      <S.ContactListItem>
        <S.ContactListItemLabel>
          <PhoneIcon color={theme.colors.secondary} />
          Telefon:
        </S.ContactListItemLabel>
        <PhoneLink phoneNumber={contacts.phoneNumber} />
      </S.ContactListItem>
      <S.ContactListItem>
        <S.ContactListItemLabel>
          <EmailIcon color={theme.colors.secondary} />
          Email:
        </S.ContactListItemLabel>
        <EmailLink email={contacts.email} />
      </S.ContactListItem>
      <S.ContactListItem>
        <S.ContactListItemLabel>
          <LocationIcon color={theme.colors.secondary} />
          Adresa:
        </S.ContactListItemLabel>
        <Anchor
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.google.com/maps/place/Dent%C3%A1ln%C3%AD+hygiena+Petra+Kro%C4%8Dilov%C3%A1,+DiS./@49.9353343,17.2682613,19z/data=!3m1!4b1!4m13!1m7!3m6!1s0x4712247aa6368ba7:0xc6d5ab72abb0006b!2zQmFydMOha292YSA2MDIvMjEsIDc5NSAwMSBSw71tYcWZb3Y!3b1!8m2!3d49.9353343!4d17.2688085!3m4!1s0x4712258f28230aa5:0x568904422882cdb8!8m2!3d49.9353343!4d17.2688085"
        >
          <S.AddressContainer>{contacts.address}</S.AddressContainer>
        </Anchor>
      </S.ContactListItem>
      <S.ContactListItem>
        <S.ContactListItemLabel>
          <InfoIcon color={theme.colors.secondary} />
          IČO:
        </S.ContactListItemLabel>
        {contacts.companyRegistrationNumber}
      </S.ContactListItem>
      <S.TopAlignedContactListItem>
        <S.ContactListItemLabel>
          <AccessTimeIcon color={theme.colors.secondary} />
          Provozní doba:
        </S.ContactListItemLabel>
        <S.ScheduleContainer>
          <S.ScheduleItem>Pondělí: 08:00 - 16:00</S.ScheduleItem>
          <S.ScheduleItem>Úterý: 13:00 - 18:00</S.ScheduleItem>
          <S.ScheduleItem>Středa: 08:00 - 16:00</S.ScheduleItem>
          <S.ScheduleItem>Čtvrtek: 08:00 - 15:00</S.ScheduleItem>
          <S.ScheduleItem>Pátek: 08:00 - 15:00</S.ScheduleItem>
        </S.ScheduleContainer>
      </S.TopAlignedContactListItem>
    </ul>
  )
}
