import React from 'react'
import type { ReactNode } from 'react'

import Anchor from '../anchor'

interface EmailLinkProps {
  email: string

  children?: ReactNode
}

export default function EmailLink({ email, children }: EmailLinkProps) {
  return <Anchor href={`mailto:${email}`}>{children ?? email}</Anchor>
}
