import React from 'react'
import WarningIcon from '../icons/warning'

import * as S from './styles'

export default function Announcement() {
  return (
    <S.AnnouncementContainer>
        <WarningIcon />
      <S.AnnouncementContent>
        Vážení pacienti, ordinace dentální hygieny je v omezeném provozu opět otevřena. Objednání je možné <b>pouze přes SMS nebo email</b>. Děkujeme za pochopení a přejeme vše nejlepší v roce 2024.
      </S.AnnouncementContent>
    </S.AnnouncementContainer>
  )
}
