import styled from 'styled-components'

import theme from '@theme'
import { toRem } from '@theme/utils'

const Card = styled.div`
  background-color: ${theme.colors.white[900]};
  box-shadow: rgba(0, 0, 0, 0.2) 0 4px 2px -2px, rgba(0, 0, 0, 0.14) 0 2px 2px 0,
    rgba(0, 0, 0, 0.12) 0 2px 6px 0;
  border-radius: ${toRem(4)};
`

export default Card
