import React from 'react'

import { AnimatedPath } from '../styles'

import type { IIconProps } from '../types'

export default function InfoIcon({ color = '#000' }: IIconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <title>info</title>
      <path d="M0 0h24v24H0z" fill="none" />
      <AnimatedPath
        fill={color}
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"
      />
    </svg>
  )
}
