import styled from 'styled-components'

import H3 from '../headings/h3'
import theme from '@theme'

const CardHeader = styled(H3)`
  margin: ${theme.spacing[1]} 0;
  padding: 0 ${theme.spacing[1]};
  font-weight: 500;
  color: ${theme.colors.gray[700]};
`

export default CardHeader
