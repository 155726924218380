import React from 'react'

import { AnimatedPath } from '../styles'

import type { IIconProps } from '../types'

export default function WarningIcon({ color = 'currentColor' }: IIconProps) {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>warning</title>
      <circle cx="18" cy="18" r="17" stroke={color} strokeWidth="2" />
      <AnimatedPath
        d="M19.7206 10.7668C19.7463 9.7987 18.9685 9 18 9c-.9685 0-1.7463.79869-1.7206 1.7668L16.52 19.85c.0212.8021.6776 1.4413 1.48 1.4413s1.4588-.6392 1.48-1.4413l.2406-9.0832ZM16 24.8859c0 1.18.814 2.1141 2 2.1141s2-.9341 2-2.1141c0-1.2537-.814-2.0895-2-2.0895s-2 .8358-2 2.0895Z"
        fill={color}
      />
    </svg>
  )
}
