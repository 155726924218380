import { showToast } from '@components/core/toast-notification'
import { useEffect } from 'react'

export default function useToastAnnouncement(announcementMessage: string, delayMs = 1500) {
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      showToast(announcementMessage)
    }, delayMs)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [])
}
