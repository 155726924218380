import React, { useLayoutEffect, useRef, useState } from 'react'
import debounce from 'lodash.debounce'

import * as S from './styles'

const INITIAL_MAP_SIZE = {
  width: 1520,
  height: 300,
}
const DEBOUNCE_TIME = 500

export default function FooterMap() {
  const [mapSize, setMapSize] = useState(INITIAL_MAP_SIZE)
  const targetRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    const debouncedHandleResize = debounce(() => {
      const { width, height } = targetRef.current?.getBoundingClientRect() ?? INITIAL_MAP_SIZE

      setMapSize({ width, height })
    }, DEBOUNCE_TIME)

    window.addEventListener('resize', debouncedHandleResize)

    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  }, [setMapSize])

  return (
    <S.MapContainer ref={targetRef}>
      <iframe
        allowFullScreen
        aria-hidden={false}
        title="Google Map"
        // eslint-disable-next-line max-len
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d574.6195170439486!2d17.268500618040584!3d49.9352198597623!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4712258f28230aa5%3A0x568904422882cdb8!2zRGVudMOhbG7DrSBoeWdpZW5hIFBldHJhIEtyb8SNaWxvdsOhLCBEaVMu!5e0!3m2!1sen!2scz!4v1597779848448!5m2!1sen!2scz"
        width={mapSize.width}
        height={mapSize.height}
        frameBorder={0}
      />
    </S.MapContainer>
  )
}
