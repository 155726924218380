import styled from 'styled-components'

import theme from '@theme'

const Section = styled.section`
  padding: ${theme.spacing[3]} ${theme.spacing[1]} ${theme.spacing[3]};
  background-color: ${theme.colors.gray[100]};

  @media screen and (min-width: ${theme.breakpoints.xl}) {
    padding: ${theme.spacing[5]} ${theme.spacing[4]} ${theme.spacing[5]};
  }

  @media screen and (min-width: ${theme.breakpoints['4k']}) {
    padding: ${theme.spacing[5]};
  }
`
export default Section
