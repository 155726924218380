import React from 'react'

import WarningIcon from '../icons/warning'
import {
  ToastNotificationContainer,
  ToastNotificationIconWrapper,
  ToastNotificationMessage,
} from './styled'

export interface IToastNotificationProps {
  message: string
}

export default function ToastNotification({ message }: IToastNotificationProps) {
  return (
    <ToastNotificationContainer>
      <ToastNotificationIconWrapper>
        <WarningIcon />
      </ToastNotificationIconWrapper>
      <ToastNotificationMessage>{message}</ToastNotificationMessage>
    </ToastNotificationContainer>
  )
}

export * from './utils'
