import React from 'react'
import type { FocusEventHandler, MouseEventHandler, ReactNode } from 'react'

import * as S from './styles'

interface IPhoneLinkProps {
  phoneNumber: string

  children?: ReactNode
  onMouseEnter?: MouseEventHandler
  onMouseLeave?: MouseEventHandler
  onFocus?: FocusEventHandler
  onBlur?: FocusEventHandler
}

export default function PhoneLink({
  phoneNumber,
  children,
  onMouseEnter,
  onMouseLeave,
  onFocus,
  onBlur,
}: IPhoneLinkProps) {
  return (
    <S.PhoneAnchor
      href={`tel:${phoneNumber}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onFocus={onFocus}
      onBlur={onBlur}
    >
      {children ?? phoneNumber}
    </S.PhoneAnchor>
  )
}
