import styled from 'styled-components'

import theme from '@theme'
import { toRem } from '@theme/utils'

export const FooterContainer = styled.footer`
  padding-top: ${theme.spacing[3]};
  background-color: ${theme.colors.gray[100]};

  @media screen and (min-width: ${theme.breakpoints.xl}) {
    padding-top: ${theme.spacing[5]};
  }
`

export const Container = styled.div`
  padding: 0 ${theme.spacing[1]};

  @media screen and (min-width: ${theme.breakpoints.xl}) {
    padding: 0 ${theme.spacing[4]};
  }

  @media screen and (min-width: ${theme.breakpoints['4k']}) {
    padding: 0 ${theme.spacing[5]};
  }
`

export const ContactInfoContainer = styled(Container)`
  margin-bottom: ${theme.spacing[3]};
  display: grid;
  grid-template-rows: auto;
  gap: ${theme.spacing[1]};

  @media screen and (min-width: ${theme.breakpoints.lg}) {
    grid-template-columns: 1fr 2fr;
    align-items: center;
    gap: ${theme.spacing[2]};
  }
`

export const SitemapContainer = styled(Container)`
  background-color: ${theme.colors.pink[500]};
`

export const CopyrightContainer = styled.div`
  background-color: ${theme.colors.pink[500]};
  color: ${theme.colors.white[900]};
  text-align: center;
  padding: ${toRem(16)};
  font-weight: 500;
`
