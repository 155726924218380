import styled from 'styled-components'

import theme from '@theme'

const CardBody = styled.div`
  padding: 0 ${theme.spacing[1]} ${theme.spacing[1]};
  color: ${theme.colors.gray[600]};
`

export default CardBody
