import React from 'react'

import { AnimatedPath } from '../styles'

import type { IIconProps } from '../types'

export default function LocationIcon({ color = '#f000' }: IIconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <title>location</title>
      <AnimatedPath
        d="M12 2a7 7 0 00-7 7c0 5.25 7 13 7 13s7-7.75 7-13a7 7 0 00-7-7zm0 9.5a2.5 2.5 0 110-5 2.5 2.5 0 010 5z"
        fill={color}
      />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  )
}
