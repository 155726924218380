import theme from '@theme'
import { toRem } from '@theme/utils'
import styled from 'styled-components'

const ToastNotificationContainer = styled.div`
  display: grid;
  grid-template-columns: ${toRem(36)} auto;
  grid-gap: ${theme.spacing[1]};
  align-items: center;

  padding: ${theme.spacing[0]} ${theme.spacing[1]};

  color: ${theme.colors.gray[900]};
  background: ${theme.colors.white[900]};
`

const ToastNotificationIconWrapper = styled.span`
  color: ${theme.colors.primary};
`

const ToastNotificationMessage = styled.p`
  color: ${theme.colors.primary};

  font-size: ${toRem(16)};
  line-height: 1.3;
  font-weight: 700;
`

export { ToastNotificationContainer, ToastNotificationIconWrapper, ToastNotificationMessage }
