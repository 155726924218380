import styled from 'styled-components'

import theme from '@theme'
import { toRem } from '@theme/utils'

export const MapContainer = styled.div`
  height: ${toRem(300)};
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.gray[300]};
`
