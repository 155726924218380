import styled from 'styled-components'

import theme from '@theme'
import { heading } from '@theme/typography'
import { toRem } from '@theme/utils'

export const AnnouncementContainer = styled.div`
  display: grid;
  grid-template-columns: min-content auto;
  gap: ${theme.spacing[0]};

  margin-top: ${toRem(60)}; /* fixed header offset */
  padding: ${theme.spacing[2]};

  background: ${theme.colors.error};
  color: ${theme.colors.white[900]};

  @media screen and (min-width: ${theme.breakpoints.lg}) {
    align-items: center;
  }
`

export const AnnouncementContent = styled.p`
  ${heading.h3}
  margin: 0;

  color: ${theme.colors.white[900]};
`
