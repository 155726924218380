import React from 'react'

import H2 from '../headings/h2'
import ContactList from './contact-list'
import FooterMap from './map'
import Sitemap from './sitemap'

import * as S from './styles'

export default function Footer() {
  return (
    <S.FooterContainer>
      <S.Container>
        <H2>Kontakt</H2>
      </S.Container>
      <S.ContactInfoContainer>
        <ContactList />
        <FooterMap />
      </S.ContactInfoContainer>
      <S.SitemapContainer>
        <Sitemap />
      </S.SitemapContainer>
      <S.CopyrightContainer>
        Všechna práva vyhrazena © {new Date().getFullYear()} Petra Hudcová
      </S.CopyrightContainer>
    </S.FooterContainer>
  )
}
