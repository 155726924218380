import React from 'react'

import * as S from './styles'

export default function Sitemap() {
  return (
    <S.SitemapNavigation>
      <S.SitemapNavigationList>
        <li>
          <S.SitemapLink to="/">Domů</S.SitemapLink>
        </li>
        <li>
          <S.SitemapLink to="/cenik-sluzeb">Ceník služeb</S.SitemapLink>
        </li>
      </S.SitemapNavigationList>
    </S.SitemapNavigation>
  )
}
