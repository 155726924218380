import React from 'react'

import { AnimatedPath } from '../styles'

import type { IIconProps } from '../types'

export default function PhoneIcon({ color = '#000' }: IIconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <title>phone</title>
      <path d="M0 0h24v24H0z" fill="none" />
      <AnimatedPath
        d="M6.62 10.79a15.15 15.15 0 006.59 6.59l2.2-2.2a1 1 0 011.02-.24c1.12.37 2.33.57 3.57.57a1 1 0 011 1V20a1 1 0 01-1 1A17 17 0 013 4a1 1 0 011-1h3.5a1 1 0 011 1c0 1.25.2 2.45.57 3.57a1 1 0 01-.25 1.02l-2.2 2.2z"
        fill={color}
      />
    </svg>
  )
}
