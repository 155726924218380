import styled from 'styled-components'
import { Link } from 'gatsby'

import theme from '@theme'
import { toRem } from '@theme/utils'

export const SitemapNavigation = styled.nav`
  border-bottom: ${toRem(1)} solid ${theme.colors.white[900]};
  padding: ${theme.spacing[2]} 0;
`

export const SitemapNavigationList = styled.ul`
  text-align: center;

  > li {
    margin-bottom: ${theme.spacing[0]};
  }
`

export const SitemapLink = styled(Link)`
  display: block;
  padding: ${theme.spacing[0]} 0;
  font-weight: 500;
  text-decoration: none;
  color: ${theme.colors.gray[100]};
  transition: color 200ms ease-in-out;

  &:hover,
  &:active,
  &:focus {
    color: ${theme.colors.white[900]};
  }

  &:disabled {
    cursor: not-allowed;
    color: ${theme.colors.gray[200]};
  }
`
