import styled from 'styled-components'

import theme from '@theme'
import { toRem } from '@theme/utils'

export const ContactListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${theme.spacing[0]};
`

export const TopAlignedContactListItem = styled(ContactListItem)`
  align-items: flex-start;
`

export const ContactListItemLabel = styled.span`
  display: flex;
  align-items: center;
  font-weight: 500;

  > svg {
    margin-right: ${theme.spacing[0]};
  }
`

export const AddressContainer = styled.span`
  font-size: ${toRem(14)};

  @media screen and (min-width: ${theme.breakpoints.xs}) {
    font-size: inherit;
  }
`

export const ScheduleContainer = styled.ul`
  margin-top: ${toRem(4)};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export const ScheduleItem = styled.li`
  margin-bottom: ${toRem(4)};
`
