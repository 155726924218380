import React from 'react'

import { AnimatedPath } from '../styles'

import type { IIconProps } from '../types'

export default function EmailIcon({ color = '#000' }: IIconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <title>email</title>
      <AnimatedPath
        d="M20 4H4a2 2 0 00-1.99 2L2 18c0 1.1.9 2 2 2h16a2 2 0 002-2V6a2 2 0 00-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"
        fill={color}
      />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  )
}
