import styled from 'styled-components'

import theme from '@theme'
import { toRem } from '@theme/utils'
import { baseText } from '@theme/typography'

const Button = styled.button`
  ${baseText}
  border-radius: ${toRem(4)};
  padding: ${theme.spacing[0]} ${theme.spacing[1]};
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  color: ${theme.colors.white[900]};
  background-color: ${theme.colors.primary};
  border: ${toRem(2)} solid ${theme.colors.primary};
  cursor: pointer;
  will-change: color, background-color;
  transition: color 200ms ease-in-out, background-color 200ms ease-in-out;

  &:hover,
  &:focus {
    color: ${theme.colors.primary};
    background-color: ${theme.colors.white[900]};
  }

  &:active {
    background-color: ${theme.colors.pink[200]};
  }

  &:disabled {
    cursor: not-allowed;
    color: ${theme.colors.gray[200]};
    border-color: ${theme.colors.gray[200]};
    background-color: ${theme.colors.white[900]};
  }

  @media screen and (max-width: ${theme.breakpoints.sm}) {
    padding: ${theme.spacing[0]};
  }
`

export default Button
