import styled from 'styled-components'
import { Link as GatsbyLink } from 'gatsby'

import theme from '@theme'
import { toRem } from '@theme/utils'
import { heading } from '@theme/typography'

export const Header = styled.header`
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  right: 0;
  height: ${toRem(60)}; /* TODO: export as constant */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${theme.spacing[1]};
  box-sizing: border-box;
  background-color: ${theme.colors.white[900]};
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
  color: ${theme.colors.gray[700]};
`

export const LogoText = styled.span`
  font-size: ${heading.h3};
  font-weight: 300;
`

export const ActionItemWrapper = styled.span`
  margin-left: ${theme.spacing[0]};

  @media screen and (max-width: ${theme.breakpoints.sm}) {
    margin-left: ${theme.spacing[1]};
  }
`

export const CenteredContainer = styled.div`
  display: flex;
  align-items: center;
`

export const CenteredFlex = styled.span`
  display: flex;
  align-items: center;
`

export const Link = styled(GatsbyLink)`
  width: ${toRem(145)};

  @media screen and (max-width: ${theme.breakpoints.xxs}) {
    width: ${toRem(40)};
  }
`
