import { useStaticQuery, graphql } from 'gatsby'
import type { FluidObject } from 'gatsby-image'

import { serviceData } from '@resources/data/services'
import type { IService } from '@resources/data/services'

interface ServiceDataWithFluidImage extends IService {
  fluidImage: FluidObject
}

interface IUseServicesDataInput {
  limit?: number
}

export default function useServicesData({
  limit,
}: IUseServicesDataInput = {}): ServiceDataWithFluidImage[] {
  const images = useStaticQuery(graphql`
    query {
      initialExaminationService: file(relativePath: { eq: "initial-examination-service.jpeg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      recallService: file(relativePath: { eq: "recall-service.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      unordinaryRecallService: file(relativePath: { eq: "unordinary-recall-service.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      childrenService: file(relativePath: { eq: "children-service.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      toothWhiteningService: file(relativePath: { eq: "tooth-whitening-service.jpeg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const serviceDataWithImages = serviceData.map((service) => ({
    ...service,
    fluidImage: images[service.imageKey].childImageSharp.fluid,
  }))

  return limit && limit > 0 ? serviceDataWithImages.slice(0, limit) : serviceDataWithImages
}
