import styled from 'styled-components'

import theme from '@theme'
import { baseText } from '@theme/typography'
import { toRem } from '@theme/utils'

interface ITextButtonProps {
  color?: string
  hoverColor?: string
}

const TextButton = styled.button<ITextButtonProps>`
  ${baseText}
  box-sizing: border-box;
  padding: ${toRem(4)} ${theme.spacing[0]};
  border: none;
  font-weight: 500;
  color: ${(props) => props.color ?? theme.colors.secondary};
  background-color: transparent;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: ${toRem(4)};
  will-change: background-color;
  transition: background-color 200ms ease-in-out;

  &:hover:enabled,
  &:focus,
  &:active:enabled {
    background-color: ${(props) => props.hoverColor ?? theme.colors.cyan[100]};
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
    color: ${theme.colors.gray[200]};
  }
`

export default TextButton
